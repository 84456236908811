* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #222;
}
code {
  font-family: 'Roboto Mono', monospace;
  letter-spacing: -0.1px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 8px;
}
p {
  margin: 0 0 16px 0;
  line-height: 160%;
  overflow-wrap: break-word;
}
a {
  color: #f22f52;
  text-decoration: none;
}
img {
  max-width: 100%;
  width: 100%;
}
.link-text:hover,
p a:hover {
  border-bottom: 1px solid #f22f52;
}
.error-msg,
.success-msg {
  color: red;
  margin: 8px 0;
  font-size: 14px;
}
.success-msg {
  color: #56ca1a;
}
.link-text {
  cursor: pointer;
}
.cancel-text {
  color: #222;
  font-size: 12px;
  font-family: 'Roboto Mono', monospace;
}
.cancel-confirm-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  margin: 40px 0;
}
time {
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  letter-spacing: -0.1px;
}
.page-not-found {
  padding-top: 10vh !important;
  text-align: center;
}

/* Layout */
.Container {
  max-width: 800px;
  margin: 0 auto;
}
.Container--lg {
  max-width: 1000px;
}
.Container--md {
  max-width: 600px;
}
.Container--sm {
  max-width: 400px;
}
.Container--side-padding {
  padding: 0 16px;
}
.gray-bg {
  background: #eaeaea;
}

/* Form elements */
input,
button,
textarea,
.cta-btn {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  margin: 4px 0;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  -webkit-appearance: none;
}
button,
.cta-btn {
  background: #f22f52;
  border: none;
  color: #f9f9f9;
  cursor: pointer;
  font-weight: bold;
}
.cta-btn {
  display: inline-block;
  width: auto;
  padding: 16px 40px;
}
input:disabled,
button:disabled {
  opacity: 0.4;
}
.btn-secondary {
  color: #444;
  background: #d4d4d4;
  padding: 12px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease-in;
  font-weight: bold;
}
.btn-secondary-cta {
  background: #f22f52;
  border: none;
  color: #f9f9f9;
}
.sm-form {
  text-align: center;
  padding: 6vh 16px;
  animation: fade-in-up ease 0.8s forwards;
}
.sm-form h3 {
  margin-bottom: 16px;
}
.link-btn {
  background: 0;
  padding: 0;
  margin: 0;
  width: auto;
  font-size: inherit;
  cursor: pointer;
}
.form-field {
  margin: 16px 0;
}
.select-new {
  display: grid;
  margin: -0;
  padding: 2px 10px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: inherit;
  color: inherit;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23CCCCCC%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-new:focus {
  outline: none;
}
.select-new:disabled,
.select-new[aria-disabled='true'] {
  background-color: inherit;
  color: inherit;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
}
input[type='checkbox'] {
  width: 12px;
  height: 12px;
  -webkit-appearance: checkbox;
  margin: 0 8px -8px 2px;
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  padding: 6px;
  border: 1px solid #444;
}
input[type='file'] {
  background: inherit !important;
}

/* Select a plan */
.select-plan input[type='radio'] {
  display: none;
}
.select-plan {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  border: 1px solid #777;
  border-radius: 4px;
  align-items: center;
}
.select-plan label {
  width: 100%;
  display: inline-block;
  padding: 8px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
.select-plan label small {
  font-weight: 300;
}
.select-plan input[type='radio']:checked + label {
  background: #444;
  color: #fff;
}

/* Main navigation */
header {
  padding: 8px;
}
.main-nav {
  display: grid;
  grid-template-columns: 60px auto;
  align-items: center;
}
.main-nav-menu {
  justify-self: end;
}
.main-nav ul,
.sub-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  justify-self: end;
  display: grid;
  width: 100%;
}
.non-member {
  grid-template-columns: auto auto auto;
}
.member {
  grid-template-columns: 44px 44px 44px 44px 44px;
}
.sub-nav {
  grid-template-columns: 100px 70px 70px auto;
  padding: 8px;
  border-top: 1px solid #f4f4f4;
}
.sub-nav li:last-child {
  text-align: right;
}
.main-nav li,
.sub-nav li {
  display: inline-block;
  align-self: center;
}
.main-nav li a,
.sub-nav a,
.main-nav .link-text,
.sub-nav .link-text {
  padding: 4px 8px;
  color: #222;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
}
.sub-nav li:first-child .link-text {
  padding-left: 0;
}
.sub-nav li:first-child .link-text ion-icon,
.write-journal .sub-nav ion-icon {
  font-size: 16px !important;
  vertical-align: sub;
}
.sub-nav a:hover,
.my-account-hover-menu .link-text:hover,
.sub-nav .link-text:hover,
.member-list-item a:hover {
  border: none;
}
.sub-nav__public {
  background: #f9f9f9;
}
.main-nav ion-icon {
  font-size: 24px;
  color: #999;
  vertical-align: middle;
}
.logo-nav {
  padding: 4px 4px 0 4px;
}
.logo-mobile {
  max-width: 26px;
  margin-right: 6px;
}
.logo-desktop {
  display: none;
}
.logo-sa {
  width: 100%;
  max-width: 160px;
}
.logo-loading {
  max-width: 80px;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  animation: bounce 1.6s infinite ease-in-out;
}
.profile-pic {
  background: #eaeaea;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
  float: left;
  margin-right: 8px;
}
.main-nav .profile-pic {
  width: 24px;
  height: 24px;
  margin: 0;
  float: none;
}
.lvl-1 {
  border: 2px solid #ffee00;
}
.lvl-2 {
  border: 2px solid #00c2ff;
}
.lvl-3 {
  border: 2px solid #00ff0a;
}
.lvl-4 {
  border: 2px solid #e600ff;
}
.lvl-5 {
  border: 2px solid #0adab5;
}
.lvl-6 {
  border: 2px solid #da133f;
}
.lvl-7 {
  border: 2px solid #000;
}
.profile-pic--large {
  max-width: 120px;
  max-height: 120px;
  height: 120px;
  width: 120px;
  border-width: 6px;
  cursor: pointer;
  display: inline-block;
}
.user-profile-pic {
  position: relative;
}
.upload-image-icon {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  padding-top: 48px;
}
.upload-image-icon img {
  width: 32px;
}
.user-profile-pic:hover > .upload-image-icon {
  display: block;
}
.author-page .user-profile-pic:hover > .upload-image-icon {
  display: none;
}
.author-page .profile-pic--large {
  cursor: inherit;
}
.nav-notification {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}
.nav-notification .badge {
  position: absolute;
  top: -5px;
  right: 4px;
  padding: 1px 4px;
  width: 16px;
  height: 16px;
  font-size: 10px;
  display: grid;
  align-items: center;
  text-align: center;
  background: #f22f52;
  color: white;
}
.bible-nav-logo {
  text-align: right;
}
.happy-nav-logo img {
  max-width: 60px;
}
.bible-nav-logo img {
  max-width: 50px;
}
.happy-nav-logo-white,
.bible-nav-logo-white {
  display: none;
}
.sub-nav-logos {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  position: relative;
}
.sub-nav-logos a {
  width: auto !important;
}
.sub-nav-logos:after {
  content: '/';
  position: absolute;
  left: 80px;
  top: 10px;
}

/* Write journal */
.write-journal .sub-nav {
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  margin-top: -51px;
  z-index: 1;
  position: relative;
  background: #fff;
  color: #111;
  border-bottom: 1px solid #f4f4f4;
  padding: 12px 8px;
  font-size: 14px;
}
.write-journal .sub-nav .link-btn {
  color: #111;
}
.write-journal .sub-nav li:last-child .link-btn {
  font-weight: bold;
  color: #f22f52;
}

.write-journal .sub-nav .book-select {
  background-color: #fff;
  border: 1px solid #eaeaea;
}
.write-journal .sub-nav li:first-child {
  text-align: left;
}
.write-journal .sub-nav li:nth-child(2) {
  font-size: 18px;
}
.write-journal .sub-nav a,
.journal-entry .sub-nav a {
  padding: 4px 0;
}
.journal-option {
  padding: 8px;
  display: grid;
  grid-template-columns: 200px 100px;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f4;
}
.journal-option time {
  align-self: center;
}
.ce-inline-tool {
  padding: 0;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper input {
  font-size: 12px;
  font-family: 'Roboto Mono', monospace;
  width: inherit;
}
.react-datepicker-time__input {
  padding: 2px;
  font-size: 14px;
  align-items: end;
  width: 100% !important;
}

/* My journal */
.journal-list,
.book-journals,
.my-books {
  margin-bottom: 64px;
}
.write-journal-cta {
  background: #f22f52;
  padding: 8px 16px;
}
.write-journal-cta a {
  color: #f9f9f9;
}
.write-journal-cta .Container {
  position: relative;
}
.journal-prompt-btn {
  position: absolute;
  top: 36px;
  bottom: 0;
  right: 8px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  text-align: center;
  font-size: 24px;
}
.write-input {
  background: #fff;
  padding: 16px;
  color: #999;
  margin: 8px 0;
  border-radius: 4px;
}
.my-journal-list {
  display: none;
}
.journal-list-title {
  padding: 16px;
  font-size: 14px;
}
.journal-list-month {
  border-radius: 4px;
  margin-bottom: 8px;
  border: 1px solid #f8f8f8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}
.journal-by-month {
  padding: 8px 16px;
  font-weight: bold;
  cursor: pointer;
}
.journal-by-month time,
.journal-by-month ion-icon {
  color: #f23051;
}
.journal-by-month ion-icon,
.journal-list-title ion-icon {
  float: right;
  font-size: 18px;
  cursor: pointer;
}
.journal-list-item {
  padding: 16px;
  background: #fff;
  border-bottom: 1px solid #f8f8f8;
}
.journal-list-item.draft {
  opacity: 0.4;
}
.journal-list-item p {
  margin: 4px 0;
}
.journal-list-item a,
.author a {
  color: #222;
}
.journal-list .journal-list-item:last-child {
  border-bottom: none;
}
.search-journal {
  display: none;
  margin-top: -16px;
  margin-bottom: 8px;
  padding: 0 16px;
}
.author {
  padding: 8px 0;
}
.journal-list-item-header {
  margin-bottom: 8px;
}
.journal-list-item-header span {
  float: right;
  text-transform: capitalize;
  margin: 2px;
}
.journal-entry-privacy span {
  text-transform: capitalize;
}
.journal-list-item-footer {
  margin: 16px 0 8px 0;
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
}
.journal-list-item-footer ion-icon {
  vertical-align: middle;
  font-size: 18px;
}
.journal-list-item-footer > div {
  display: inline-block;
  margin-right: 16px;
}
.member-list-item {
  padding: 8px 16px;
  font-size: 14px;
}
.journal-card {
  border: 1px solid #f8f8f8 !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
  width: calc(100% - 16px);
  margin: 8px;
  border-radius: 4px;
}
.journal-card .journal-featured-image {
  margin: 8px 0;
}
@media (min-width: 480px) {
  .journal-card {
    width: calc(50% - 16px);
  }
}

.my-stats {
  background: rgb(59 193 131 / 16%);
  padding: 24px;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}
.challenge-weelky {
  list-style: none;
  margin: 16px auto 24px auto;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 4px;
  max-width: 360px;
}
.challenge-weelky li {
  border: 1px dashed rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: inline-block;
}
.challenge-weelky li.completed {
  border: 2px solid #3bc183 !important;
  color: #3bc183 !important;
}
.challenge-card {
  display: inline-block;
  width: calc(50% - 8px);
  margin: 4px;
  border: 1px solid #f8f8f8 !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  text-align: center;
  vertical-align: top;
}
.challenge-card .profile-pic {
  width: 60px;
  height: 60px;
  float: none;
  display: block;
  margin: 4px auto;
}
.challenge-card-info {
  word-break: break-all;
}
.challenge-card-info code {
  margin: 4px auto;
  display: inline-block;
}
.challenge-card .word-count {
  display: block;
}
@media (min-width: 768px) {
  .challenge-card {
    text-align: left;
  }
  .challenge-card .profile-pic {
    float: left;
  }
  .challenge-card .author {
    display: grid;
    width: 100%;
    margin: 0;
    padding: 0;
    grid-template-columns: 76px 1fr;
    align-items: center;
  }
  .challenge-card-info code {
    margin: 0 0 4px 0;
  }
  .challenge-card .word-count {
    display: inline-block;
    padding-left: 8px;
  }
}

.journal-entry,
.follower-page,
.following-page,
.book-journals,
.feedback {
  padding-bottom: 40px;
}
.journal-entry-content {
  line-height: 160%;
}
.journal-entry-content img {
  max-width: 100%;
}
.journal-comments,
.journal-comment-write {
  margin-top: 64px;
  white-space: pre-line;
}
.journal-comment-write textarea,
.journal-comments textarea {
  min-height: 120px;
}
.comment-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.comment-list div:last-child {
  text-align: right;
  font-size: 10px;
}
.comment-edit-ui span {
  cursor: pointer;
}
.comment-edit-ui {
  font-size: 12px;
  text-align: right;
  font-family: 'Roboto Mono', monospace;
}
.sub-nav-back,
.journal-entry .sub-nav {
  grid-template-columns: auto 40px 40px;
  text-align: right;
  margin-bottom: 16px;
  height: 44px;
}
.sub-nav-back li:last-child {
  text-align: center;
}
.sub-nav-back li:first-child,
.journal-entry .sub-nav li:first-child {
  text-align: left;
}
.sub-nav-back ion-icon,
.journal-entry .sub-nav ion-icon,
.write-journal .sub-nav ion-icon {
  font-size: 20px;
  vertical-align: bottom;
}
.journal-entry .edit-sub-nav {
  grid-template-columns: auto 58px 50px;
}
.journal-loved-ui {
  margin-top: 24px;
}
.journal-loved-ui ion-icon {
  font-size: 24px;
}
.journal-loved-ui a {
  color: inherit;
}
.load-more,
.follow,
.following {
  margin: 16px auto;
  max-width: 120px;
  text-align: center;
}
.following {
  background: #3bc183;
  color: #f8f8f8;
}
.following ion-icon {
  vertical-align: middle;
  font-size: 12px;
}
button.btn-secondary.following {
  display: block;
  margin: 16px 0;
}
.cursor {
  cursor: pointer;
}
.follow-list,
.loved-list,
.billing-list {
  display: block !important;
  grid-template-columns: initial;
  grid-template-rows: initial;
}
.follow-list > div,
.loved-list > div,
.billing-list > div {
  text-align: left !important;
  border-bottom: 1px solid #f9f9f9;
}

/* My account */
.my-account.sub-nav {
  padding: 10px 8px 13px 8px;
}
.my-account-menu {
  position: relative;
  text-align: center;
  cursor: pointer;
}
.my-account-hover-menu {
  display: none;
  position: absolute;
  z-index: 999999;
  top: 32px;
  right: 0;
  padding: 8px 16px;
  background: #fff;
  border: 1px solid #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  text-align: left;
}
.my-account-hover-menu a,
.my-account-hover-menu .link-text {
  display: inline-block;
  width: 160px;
  padding: 10px 0 !important;
}
.my-account-hover-menu:before {
  content: '';
  position: absolute;
  top: -8px;
  right: 12px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
}
.user-profile {
  padding: 0 0 16px 0;
  display: grid;
  grid-template-columns: 140px auto auto;
  margin-top: 16px;
}
.user-profile > div {
  align-self: center;
}
.user-profile h3 {
  margin: 4px 0;
}
.badge {
  background: #3bc183;
  padding: 2px 8px;
  border-radius: 12px;
  color: #fff;
  font-size: 12px;
  margin: 4px 0;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-weight: 300;
}
.badge--cta {
  background: #f22f52;
}
.badge--black {
  background: #444;
}
.badge--grey {
  background: #aaa;
}
.user-stats {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 16px;
}
.user-stats a {
  color: #222;
}
.user-stats > div {
  padding: 8px;
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  text-align: center;
}
.user-stats ion-icon {
  vertical-align: middle;
  font-size: 16px;
}
.user-profile,
.author-profile {
  grid-template-columns: 1fr;
  text-align: center;
}
.user-stats {
  grid-column-start: initial;
  grid-column-end: initial;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
}
.user-profile .user-stats {
  margin-top: 16px;
}
.author-profile button.btn-secondary.following,
.author-profile button.btn-secondary.message {
  display: inline-block;
}
.user-profile .profile-pic,
.author-profile .profile-pic {
  float: initial;
  margin: 0 0 8px 0;
}
.my-public-journals {
  margin-top: 40px;
  padding-bottom: 32px;
}
.Billing > div,
.setting > div,
.notification-page {
  margin-bottom: 48px;
}
.level-list-item,
.notification-list-item,
.chat-list-item {
  display: grid;
  grid-template-columns: 120px auto;
  padding: 16px;
  border-bottom: 1px solid #f8f8f8;
  align-items: center;
}
.notification-list-item,
.chat-list-item {
  grid-template-columns: 48px auto 100px;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 14px;
}
.chat-list-item {
  grid-template-columns: 48px auto;
  border: 1px solid #f8f8f8 !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
  margin: 8px;
  border-radius: 4px;
}
.notification-list-item > div:last-child {
  text-align: right;
}
.unread {
  background-color: #f8f8f8;
  font-weight: 700;
}
.notification-list-item .badge,
.chat-list-item .badge {
  text-transform: capitalize;
}

/* Chat UI */
/* .chat-room{
  height: 100vh;
} */
.main-header.sticky-main-header,
.chat-ui-top,
.chat-form {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 800px;
  z-index: 99999;
}
.main-header.sticky-main-header,
.chat-ui-top {
  top: 0;
  bottom: auto;
  background: #fff;
  max-width: 100%;
}
.chat-ui-top {
  z-index: 9999;
  top: 50px;
  grid-template-columns: 40px auto 40px;
  margin: 0 auto;
  width: 100%;
}
.chat-ui-top .chat-room-title {
  text-align: center;
  overflow: hidden;
}
.sub-nav li:first-child .chat-room-goback {
  padding-left: 8px;
}
.sub-nav li:first-child .chat-room-goback ion-icon {
  font-size: 20px !important;
  vertical-align: bottom !important;
}
.chat-message-wrapper {
  padding-top: 110px;
  padding-bottom: 80px;
}
.chat-message-wrapper div {
  width: 100%;
  display: inline-block;
}
.chat-message-wrapper div p {
  background: #f8f8f8;
  padding: 12px 16px;
  border-radius: 24px;
  float: left;
  max-width: 80%;
  margin-bottom: 8px;
}
.chat-message-wrapper div.my-message p {
  background: #3bc183 !important;
  color: #f9f9f9;
  float: right;
}
.chat-form form {
  display: grid;
  grid-template-columns: 1fr 80px;
  column-gap: 8px;
  padding: 8px;
  background: #fff;
}
.message,
.my-message {
  white-space: pre-line;
}
.message > p {
  position: relative;
}
.my-message > p {
  cursor: pointer;
}
.my-message > .message-content .chat-options {
  background: #f8f8f8;
  border-radius: 16px;
  padding: 0 12px;
  color: #222;
  display: none;
  font-size: 12px;
  cursor: pointer;
}
.my-message > .message-content .chat-options.show {
  display: block;
  position: absolute;
  left: -72px;
  bottom: 16px;
}
.chat-option-loading {
  padding: 4px;
  height: 32px;
}
.chat-option-loading .loading-icon {
  padding-top: 4px;
}
.no-chat-found {
  margin-top: 40px;
  background: #fff;
}
.no-chat-found button {
  width: auto;
}
.load-more-chat-messages {
  margin: 0 auto;
  text-align: center;
  padding: 0 16px 16px 16px;
}
.load-more-chat-messages button {
  width: auto;
}
.chat-alert {
  text-align: center;
}
.chat-alert small {
  border: 1px solid #eaeaea;
  border-radius: 24px;
  padding: 4px 16px;
  font-family: 'Roboto Mono', monospace;
}
.chat-alert ion-icon {
  vertical-align: middle;
}
.chat-message-username {
  color: #222;
}

/* Switch UI */
.switch-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 16px !important;
}
.switch-wrap p,
.switch-wrap label {
  margin: 0;
  align-self: center;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  justify-self: end;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #3bc183;
}
input:focus + .slider {
  box-shadow: 0 0 1px #3bc183;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

/* Challenge */
.challenge .header img {
  max-width: 280px;
  display: grid;
  margin: 0 auto;
  margin-top: -20px;
  z-index: -1;
  min-height: 250px;
  position: relative;
}
.challenge,
.notification {
  margin-bottom: 64px;
}

/* Footer */
.footer {
  margin: 40px auto;
  text-align: center;
  font-size: 12px;
  font-family: 'Roboto Mono', monospace;
  line-height: 200%;
}
.footer a {
  padding: 4px;
}
.show {
  display: block;
}

/* Modal box */
.ReactModal__Overlay {
  z-index: 9999999;
}
.modal-box {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background: #fff;
  text-align: center;
  max-width: 560px;
  padding: 24px;
  border-radius: 8px;
}
.modal-box a:hover {
  border: none;
}
.modal-box button.link-btn {
  color: #222;
  border-bottom: 1px solid #666;
  border-radius: 0;
}

/* Book UI */
.book-wapper {
  background: #f4f4f4;
}
.book-control {
  display: grid;
  grid-template-columns: auto 40px;
  padding: 12px 16px;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.book-control span:first-child {
  text-align: left;
}
.book-control ion-icon {
  font-size: 18px;
  vertical-align: middle;
  cursor: pointer;
}
.book-list {
  overflow: scroll;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-gap: 8px;
  padding: 16px;
}
.book-list div {
  display: grid;
  width: 100px;
  height: 140px;
  align-items: flex-end;
  background-size: cover;
  background-position: center center;
  text-align: center;
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 2px 8px 2px 2px;
}
.book-list:after {
  content: '';
  width: 16px;
  margin-left: -8px;
}
.book-list.hide {
  display: none;
}
.book-list div span {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}
.my-books .book-list {
  grid-auto-flow: initial;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  margin: 16px auto;
}
.my-books .book-list div {
  width: 100%;
  height: 240px;
}
.modal-box:focus {
  outline: none !important;
}
.book-add-modal {
  text-align: left;
}
.book-add-modal h1 {
  text-align: center;
  margin-top: 8px;
}
.book-add-modal .error-msg {
  text-align: center;
}
.book-add-modal input[type='file'] {
  border: none;
  padding: 0;
  font-size: 12px;
  -webkit-appearance: none;
}
.default-book {
  background: linear-gradient(
    124deg,
    #f32f51,
    #ffba36,
    #3cce65,
    #2187e5
  ) !important;
}
.outline-book {
  border: 1px dashed #eaeaea !important;
  align-items: center !important;
}
.outline-book span {
  background: none !important;
}
.book-select {
  background-color: #333;
  border: none;
  margin-bottom: -2px;
}
.book-present {
  text-align: center;
}
.book-present-cover {
  width: 180px;
  height: 240px;
  margin: 0 auto;
  background-position: center center;
  border-radius: 2px 8px 2px 2px;
  border: 1px solid #f8f8f8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}
.book-tag ion-icon {
  font-size: 14px;
  vertical-align: bottom;
  padding: 0 2px;
}
.book-tag:hover {
  border: none;
}
.reorder-mode-dng > div {
  touch-action: none;
  cursor: move;
}

@media (min-width: 480px) {
  .book-list {
    grid-auto-flow: initial;
    justify-content: center;
  }
  .my-books .book-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.credit-card,
.coupon-code-form {
  margin: 16px 0 8px 0;
}
.coupon-code-form {
  display: grid;
  grid-template-columns: auto 120px;
  grid-column-gap: 8px;
}
.coupon-code-form input,
.coupon-code-form button {
  font-size: 14px;
  padding: 8px 16px;
}
.coupon-code-form button {
  padding: 8px;
}
.StripeElement {
  box-sizing: border-box;
  height: 56px;
  padding: 18px 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  margin-bottom: 16px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* Animation */
.animate-fade-in-up {
  opacity: 0;
  animation: fade-in-up ease 0.8s forwards;
  -webkit-animation: fade-in-up ease 0.8s forwards;
}
.animation-delay-04 {
  animation-delay: 0.4s;
}
.loved {
  color: #f22f52;
  outline: none;
}
.unloved {
  color: #222;
  outline: none;
}
button.loved:disabled,
button.unloved:disabled {
  animation: heart-pop ease 1s forwards;
  -webkit-animation: heart-pop ease 1s forwards;
  opacity: 1;
}

@keyframes heart-pop {
  0% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(1.4);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-12px);
  }
}

/* Loading */
.loading-icon {
  max-width: 28px;
  padding: 2px;
}
button .loading-icon {
  margin: -10px 0;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f9f9f9;
  background: linear-gradient(to right, #eaeaea 8%, #dddddd 18%, #eaeaea 33%);
  background-size: 1000px 104px;
  height: 60px;
  position: relative;
}
.background-masker {
  background: #f9f9f9;
  position: absolute;
}
.background-masker.header-top,
.background-masker.header-top-right,
.background-masker.header-bottom-right,
.background-masker.header-middle,
.background-masker.header-bottom {
  top: 12px;
  left: 0;
  right: 0;
  height: 12px;
}
.background-masker.header-middle {
  top: 36px;
}
.background-masker.header-top-right {
  top: 0;
  right: 0;
  left: auto;
  width: 40%;
}
.background-masker.header-bottom-right {
  top: 48px;
  right: 0;
  left: auto;
  width: 20%;
}
.journalCardLoading {
  padding: 30px 15px !important;
  background: #f9f9f9;
  margin-bottom: 10px;
  border: none !important;
}
.loadingBook {
  padding: 0 !important;
  background: inherit !important;
  display: inline-block;
}
.loadingBook .animated-background {
  max-width: 180px;
  height: 240px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  border-radius: 2px 8px 2px 2px;
}
.my-book-loading {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  padding: 0 16px;
}
.book-journals .loadingBook {
  width: 100%;
}
.book-journals .animated-background {
  margin: 0 auto;
}
.h-deco {
  background: linear-gradient(90deg, #fd4966, #b35fa2, #cf65f5);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 28px;
  font-weight: bold;
}
.word-count {
  color: #aaa;
  font-family: Roboto Mono, monospace;
  font-size: 12px;
}

.share-icon {
  color: #222;
}

#setting_backup_journals_button {
  visibility: hidden;
}
.membership-receipt{
  display: none;
}

@media (min-width: 768px) {
  .user-profile {
    grid-template-columns: 140px 280px auto;
    text-align: left;
  }
  .user-stats {
    grid-column-start: auto;
    grid-column-end: auto;
    margin-top: 0;
  }
  .author-profile {
    text-align: left;
  }
  .user-profile .user-stats {
    margin-top: 0;
  }
  #setting_backup_journals_button {
    visibility: visible;
  }
  .upload-image-icon {
    margin: initial;
  }
  .membership-receipt{
    display: inline-block;
  }
}
@media (min-width: 480px) {
  .main-nav {
    grid-template-columns: 200px auto;
  }
  .main-nav-menu > ul {
    justify-content: end;
  }
  .logo-desktop {
    display: inline-block;
    max-width: 130px;
    padding: 0 0 4px 0;
  }
  .my-book-loading {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 99;
  background: #ffffffde;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 800px;
}

/* Dark mode */
body {
  transition: background-color 0.1s;
}
.dark-mode body {
  color: #f2f2f2;
}
.dark-mode,
.dark-mode .journal-list-item,
.dark-mode .journal-by-month,
.dark-mode .book-wapper,
.dark-mode .modal-box,
.dark-mode .chat-form form,
.dark-mode .main-header.sticky-main-header,
.dark-mode .no-chat-found {
  color: #f2f2f2;
  background: #000;
}
.dark-mode .main-nav li a,
.dark-mode .journal-list-item a,
.dark-mode .sub-nav a,
.dark-mode .sub-nav .link-text,
.dark-mode .user-stats a,
.dark-mode .author a,
.dark-mode .main-nav .link-text,
.dark-mode .unloved,
.dark-mode .cancel-text,
.dark-mode .share-icon,
.dark-mode .modal-box button.link-btn,
.dark-mode .write-journal .sub-nav .link-btn,
.dark-mode .chat-message-username {
  color: #f2f2f2;
}
.dark-mode .gray-bg,
.dark-mode .my-account-hover-menu,
.dark-mode .journalCardLoading,
.dark-mode .background-masker,
.dark-mode .my-account-hover-menu,
.dark-mode .unread,
.dark-mode .chat-ui-top {
  background: #111;
}
.dark-mode .badge--grey,
.dark-mode .book-wapper,
.dark-mode .modal-box,
.dark-mode .chat-message-wrapper div p {
  background: #222;
}
.dark-mode .journal-entry .sub-nav,
.dark-mode .sub-nav-back {
  border-top: 1px solid #222;
}
.dark-mode .journal-list-item,
.dark-mode .notification-list-item,
.dark-mode .journal-option,
.dark-mode .chat-list-item,
.dark-mode .follow-list > div,
.dark-mode .loved-list > div,
.dark-mode .billing-list > div {
  border-bottom: 1px solid #222;
}
.dark-mode .journal-list-month,
.dark-mode .chat-alert small {
  border: 1px solid #222;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.08);
}
.dark-mode .my-stats {
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.08);
}
.dark-mode input,
.dark-mode textarea {
  background: #f8f8f8;
  border: 1px solid #666;
}
.dark-mode .animated-background {
  background: linear-gradient(to right, #333 8%, #444 18%, #333 33%);
  background-size: 1000px 104px;
}
.dark-mode .my-account-hover-menu {
  border: 1px solid #000;
}
.dark-mode .my-account-hover-menu:before {
  border-bottom: 8px solid #222;
}
.dark-mode .happy-nav-logo-white,
.dark-mode .bible-nav-logo-white {
  display: inline-block;
}
.dark-mode .happy-nav-logo-black,
.dark-mode .bible-nav-logo-black {
  display: none;
}
.dark-mode .book-list div span {
  background: rgba(0, 0, 0, 0.4);
}
.dark-mode .challenge-card,
.dark-mode .journal-card,
.dark-mode .book-present-cover,
.dark-mode .chat-list-item {
  border: 1px solid #222 !important;
  box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1);
}
.dark-mode .sticky {
  background: rgba(0, 0, 0, 0.9);
}
.dark-mode .write-journal .sub-nav {
  background: #000;
  color: #f2f2f2;
  border-bottom: 1px solid #222;
}
.dark-mode .select-new,
.dark-mode .write-journal .sub-nav .book-select {
  background-color: inherit;
  border: 1px solid #444;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: inherit;
  color: inherit;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23CCCCCC%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.dark-mode .outline-book {
  border: 1px dashed #444 !important;
}
.dark-mode .challenge-weelky li {
  border: 1px dashed rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.4);
}
