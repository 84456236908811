.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  border: none !important;
}
.ql-container {
  font-size: 16px !important;
}
.ql-toolbar--stick {
  background: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0;
  z-index: 1;
}
.journal-readonly .ql-editor {
  padding: 0;
}
.quillEditor {
  padding-bottom: 30px;
}
.ql-snow .ql-editor img {
  width: auto;
}
